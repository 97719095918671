import { render, staticRenderFns } from "./administer.vue?vue&type=template&id=0d0c5e43&scoped=true"
import script from "./administer.vue?vue&type=script&lang=js"
export * from "./administer.vue?vue&type=script&lang=js"
import style0 from "./administer.vue?vue&type=style&index=0&id=0d0c5e43&prod&lang=scss&scoped=true"
import style1 from "./administer.vue?vue&type=style&index=1&id=0d0c5e43&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d0c5e43",
  null
  
)

export default component.exports